.BlogShortcode {
	margin: 0px;
	width: 100%;
	height: 100%;
	color: #000000;
}

.BlogShortcode__post {
	padding: 1em;
}

.BlogShortcode__img {
	background-size: cover;
	width: 100%;
	height: 300px;
}

.BlogShortcode__body {
	width: 80%;
	margin: auto;
	background-color: palette('white');
	text-align: center;
	margin-top: -40px;
}

.BlogShortcode__date {
	display: inline-block;
	background-color: palette('white');
	padding: 0.5em;
	border: 1px solid #000000;
	margin-top: -30px;
	line-height: 1em;
	margin-bottom: 10px;
}

.BlogShortcode__dateday {
	font-size: 1.4em;
}

.BlogShortcode__title {
	h3 {
		color: #000000;
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 1.5em;
		font-weight: 400;
	}
}