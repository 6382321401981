/* ==========================================================================================
 * HAMBURGER BASE STYLES
 * ========================================================================================== */

//──── Variables ──────────────────────────────────────────────────────────────────────────────────
$base__color: $main-blue;                     // Base color
$base__thick: 3px;                        // Thickness of the hamburger elements
$base__padding: 2px;

// Hamburger Size
$h_hight: 32px;
$h_width: 35px;

// Hamburger Bars
$h_bar-h: $base__thick;                                 // Thickness of bars

// Background
$h_bg: false;                                            // Do you want the hamburger to have a background??
$h_bg-color: rgba(255, 255, 255, 0.2);                 // Color of the background

// Border
$h_bor: false;                                           // Do you want the hamburger to have a border??
$h_bor-thick: 0px;                                      // Thickness of the border (takes value of $base_thick if it's set to 0px)
$h_bor-color: 0;                                        // Color of the border (takes value of $base_color if it's set to 0)
$h_bor-radius: 10%;                                     // radius of the border

@if $h_bor-thick == 0 { $h_bor-thick: $base__thick; }
@if $h_bor-color == 0 { $h_bor-color: $base__color; }

// Transition
$h_trans-dur: .3s;                                      // Transition duration - full
$h_trans-dur-50: calc(#{$h_trans-dur} / 2);             // Transition duration - 50%
$h_trans-dur-33: calc(#{$h_trans-dur} / 3);             // Transition duration - 33%
$h_trans-timing-fun: linear;                            // Transition timing function (ease, ease-in-out, etc.)

//──── Hamburger Base ──────────────────────────────────────────────────────────────────────────────────
.ham {
	&__box {
	  height: $h_hight;
	  width: $h_width;
	  padding: $base__padding;
	  border-radius: $h_bor-radius;
	  position: relative;

	  @if $h_bg == true { background: $h_bg-color; }
	  @if $h_bor == true {
		border: $h_bor-thick solid $h_bor-color;
		overflow: hidden;
	  }
	}

	&__bar {
	  display: block;
	  background: $base__color;
	  position: absolute;
	  height: $h_bar-h;
	  width: calc(100% - 2 * #{$base__padding});

	  transition-property: all;
	  transition-duration: $h_trans-dur;
	  transition-timing-function: $h_trans-timing-fun;

	  &--top { top: 15%; }
	  &--mid { top: calc(50% - #{$base__thick} / 2); }
	  &--bottom { bottom: 15%; }
	}

	&.navbar-toggler:not(.collapsed) {
	  .ham__bar {
		&--top {
		  top: calc(50% - #{$base__thick} / 2);
		  transform: rotate(45deg);
		}
		&--mid {
		  opacity: 0;
		}
		&--bottom {
		  bottom: calc(50% - #{$base__thick} / 2);
		  transform: rotate(-45deg);
		}
	  }
	}

	/* ------- ham--180 ------- */
	&.ham--180 {
	  transform: rotate(180deg);
	}
	/* ------- ham--180-0 ------- */
	&.ham--180-0.navbar-toggler:not(.collapsed) {
	  transform: rotate(180deg);
	}
}

//──────────────────────────────────────────────────────────────────────────────────────
//──── Fold ───────────────────────────────────────────────────────────────────────────
.ham--fold {
	.ham__bar {
	  &--top {
		transition: top         $h_trans-dur-50 $h_trans-dur-50 $h_trans-timing-fun,
					transform   $h_trans-dur-50 $h_trans-timing-fun;
	  }
	  &--mid {
		transition: opacity     $h_trans-dur-50 $h_trans-dur-33 $h_trans-timing-fun;
	   }
	  &--bottom {
		transition: bottom      $h_trans-dur-50 $h_trans-dur-50 $h_trans-timing-fun,
					transform   $h_trans-dur-50 $h_trans-timing-fun;
	  }
	}

	&.navbar-toggler:not(.collapsed) .ham__bar {
	  &--top {
		transition: top         $h_trans-dur-50 $h_trans-timing-fun,
					transform   $h_trans-dur-50 $h_trans-dur-50 $h_trans-timing-fun;
	  }
	  &--bottom {
		transition: bottom      $h_trans-dur-50 $h_trans-timing-fun,
					transform   $h_trans-dur-50 $h_trans-dur-50 $h_trans-timing-fun;
	  }
	}
}