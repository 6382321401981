/* CSS Document */
#footer {
	overflow:hidden;
	padding: 0.5em 0em 0em;
	@include box-sizing(border-box);
	min-height: 300px;
	border-top: none;
	text-align: center;
	ul {
		margin-left: 8px;
	}
	.copyright {
		display: inline-block;
		float: left;
	}
	.madeWithLove {
		display: inline-block;
		float: right;
	}

	.footer-top {
		display: block;
		padding-top:0.5em;
		padding-bottom:0.5em;
		border-bottom:1px solid palette('secondary');
		margin-bottom:2em;
	}

	.footer-area {
		ul.row {
			padding:0;
		}
		.footer-box {
			list-style: none;
			text-align: left;
			@include box-sizing(border-box);
			padding:1em;

			ul {
				padding:0;
				li {
					list-style: none;
					a {
						padding:.5em;
						.btn {
							display: block;
							padding:.5em;
							width:100%;
							text-align: center;
						}
					}
				}
			}
		}
	}
	#copyright {
		font-size:.8em;
	}
	.goog-te-combo {
		display: none;
	}
}
