@mixin fontawesome {
	font-family: FontAwesome !important;
}
@mixin columns($args) {
	-moz-column-count:$args;
	-webkit-column-count:$args;
	-o-column-count:$args;
	-ms-column-count:$args;
	column-count:$args;	
}
@mixin column-break($args) {
	-webkit-column-break-inside:$args;	
	-moz-column-break-inside:$args;
	-o-column-break-inside:$args;
	-ms-column-break-inside:$args;
	break-inside:$args;
}
@mixin border-radius($args...) {
	-moz-border-radius: $args;
	-webkit-border-radius: $args;	
	-o-border-radius: $args;
	-ms-border-radius: $args;
	border-radius: $args;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin box-sizing($args...){
	-moz-box-sizing: $args;
		-webkit-box-sizing: $args;
			-o-box-sizing: $args;
				-ms-box-sixing: $args;
					box-sizing: $args;
}
@mixin box-shadow ($args...) {
  -webkit-box-shadow: $args;
  -moz-box-shadow:  $args; 
  box-shadow: $args;      
}
@mixin inner-glow($args...) {
	-moz-box-shadow:  inset  $args;
   -webkit-box-shadow: inset $args;
   box-shadow:        inset $args;	
}
@mixin transform($args...) {
	-webkit-transform: $args;
	-moz-transform: $args;
	-ms-transform:$args;
	-o-transform:$args;
	transform:$args;	
}
%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}
@mixin background($imgpath, $position, $repeat) {
    
        background-image: url($imgpath) !important;
        background-position: $position !important;
        background-repeat: $repeat !important;
    
}
@mixin gradient($direction, $color-one, $color-two) {
background: -webkit-linear-gradient($direction, $color-one, $color-two)t;
background: -o-linear-gradient($direction, $color-one, $color-two);
background: -moz-linear-gradient($direction, $color-one, $color-two);
background: linear-gradient($direction, $color-one, $color-two);
}

// Colour Opacity mix
@mixin alpha-colour($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}
//@include alpha-colour('background-color', rgba(palette('secondary'), 0.2), white);
