/* CSS Document */
body, html {
	@include box-sizing(border-box);
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
	padding-top:0px;
	font-size:100%;
	font-family: font('body');
}