a.icon-link {
	&:hover {
		color: palette('primary') !important;
	}
	i:hover {
		color: palette('primary') !important;
	}
}

.btn-primary-alpha {
    background-color: rgba(218, 0, 23, 0.6);
	border: 1px solid #ffffff;
	padding-left: 1.3em;
	padding-right: 1.3em;
}