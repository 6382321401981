@font-face {
  font-family: 'starlight';
  src:  url('../../fonts/starlight.eot?lcpz1t');
  src:  url('../../fonts/starlight.eot?lcpz1t#iefix') format('embedded-opentype'),
    url('../../fonts/starlight.ttf?lcpz1t') format('truetype'),
    url('../../fonts/starlight.woff?lcpz1t') format('woff'),
    url('../../fonts/starlight.svg?lcpz1t#starlight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="starlight-icon-"], [class*=" starlight-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'starlight' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.starlight-icon-Awnings:before {
  content: "\e900";
}
.starlight-icon-Blackout-Blinds:before {
  content: "\e901";
}
.starlight-icon-Perfect-Fit:before {
  content: "\e903";
}
.starlight-icon-Pleated:before {
  content: "\e902";
}
.starlight-icon-Roller:before {
  content: "\e904";
}
.starlight-icon-Roman:before {
  content: "\e905";
}
.starlight-icon-Shutters:before {
  content: "\e906";
}
.starlight-icon-Vertical:before {
  content: "\e907";
}
.starlight-icon-Vision-Blinds:before {
  content: "\e908";
}
.starlight-icon-Wood:before {
  content: "\e909";
}