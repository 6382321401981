#HomeSlider .owl_carousel_item {
	padding: 10px;
	height: 380px;
}

#HomeSlider.owl-carousel .owl-nav button.owl-next, #HomeSlider.owl-carousel .owl-nav button.owl-prev, #HomeSlider.owl-carousel button.owl-dot {
    background: 0 0;
    color: palette('white');
    border: none;
    padding: 0!important;
    font: 7em font('body');
}

#HomeSlider.owl-carousel .owl-nav {
	width: 100%;
	position: relative;
	z-index: 10;
	margin-top: -290px;
	margin-bottom: 290px;
}

#HomeSlider.owl-carousel .owl-nav .owl-prev, #HomeSlider.owl-carousel .owl-nav .owl-next {
	background-color: transparent;
	color: palette('white');
	font-size: 7em;
	line-height: 380px;
	vertical-align: middle;
	border: none;
}

#HomeSlider.owl-carousel .owl-nav .owl-prev {
	position: absolute;
	left: 0px;
	top: 50%;
	
}

#HomeSlider.owl-carousel .owl-nav .owl-next {
	position: absolute;
	right: 0px;
	top:50%;
}

#HomeBrands .owl_carousel_item {
	padding: 10px;
}

#HomeBrands.owl-carousel .owl-nav {
	display: none;
}

#HomeBrands.owl-carousel .owl-nav button.owl-next, #HomeBrands.owl-carousel .owl-nav button.owl-prev, #HomeBrands.owl-carousel button.owl-dot {
    background: 0 0;
    color: palette('primary');
    border: none;
    padding: 0!important;
    font: 7em font('body');
	text-align: center;
}

#HomeBrands.owl-carousel .owl-dots {
	text-align: center;
}

#HomeBrands.owl-carousel button.owl-dot span {
    background: none repeat scroll 0 0 palette('primary');
    border-radius: 20px;
    display: block;
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
}