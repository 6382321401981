/* CSS Document */
form {
	width: auto;
}

#Inspirations {
	display: flex;
  	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	select {
		font-size: 1.2em;
		color: palette('white');
		background-color: transparent;
		border: 2px solid palette('white');
		border-radius: 10px;
		padding: 10px;
		margin-right: 20px;
		option {
			color: #000000;
		}
	}
}

#Inspirations_submit {
	display: flex;
  	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	select {
		font-size: 1.2em;
		color: palette('white');
		background-color: transparent;
		border: 2px solid palette('white');
		border-radius: 10px;
		padding: 10px;
		margin-right: 20px;
		option {
			color: #000000;
		}
	}
}