#inspiration_gallery {
	min-height: 300px;
	margin: 0 auto;
	width: auto;
}

.grid-sizer, .grid-item { 
	width: 100%;
	img {
		width: 100%;
		height: auto;
		margin: 0.3em;
	}
}

@media ( min-width:breakpoint('tablet-landscape') ) {
	
	.grid-item { 
		width: 20%;
		&:nth-child(3n){
			width: 30%;
		}
		&:nth-child(8n){
			width: 40%;
		}
	}
	
}