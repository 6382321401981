/* CSS Document */
	
	// Update the below font family list and fonts function with choice of font

/* ------- Font Calls ------- */
$fonts: (
	'title': 'Raleway',
	'body': 'Raleway',
	'h1': 3em,
	'h2': 2.3em,
	'h3': 1.8em,
	'h4': 1.5em,
	'h5': 1.2em,
	'h6': 1.1em,
	'p': 1em
);
@function font($key: 'title') {
	@return map-get($fonts, $key);
}

/* ------- Headings ------- */
h1, h2, h3, h4, h5, h6 {
	font-family: font('title');
	color:palette('white');
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {font-size: font('h1');}
h2 {font-size: font('h2');}
h3 {font-size: font('h3');}
h4 {font-size: font('h4');}
h5 {font-size: font('h5');}
h6 {font-size: font('h6');}

h1 {
	small {		
		strong {
			color:palette('primary');
		}
	}
}
h2 {
	small {		
		strong {
			color:palette('primary');
		}
	}
}

/* -------  ------- */
p {
	font-size:font('p');
	font-family:font('body');
	color:palette('black');
}
a {
	color:darken(palette('primary'), 10%);
	&:visited {
		
	}
	&:hover {
		color:palette('primary');
	}
	&:focus {

	}
}
small {
	
}
strong {

}

/* ------- Lists ------- */
// Unordered
ul {
	margin-left: 1.5em;
	li {
		a {
			
		}
	}
}
// Ordered
ol {
	li {
		a {
			
		}
	}
}

/* -------  ------- */
blockquote {
	&:before {
		
	}
	&:after {
		
	}
}
pre {

}