#header {
	background-color:palette('white');
	&.sticky {
		background-color: palette('white');
	}

	.container {
		min-height: 0;
	}

	.row {
		margin-left: auto;
		margin-right: auto;
	}

	.header__top {
		background-color: palette('white');
	}

	.header__bottom {
		background-color: rgba(palette('primary'), .95);
	}

	.nav-link {

		&:hover,
		&:focus {
			
		}
	}
	.menu_tel {
		a {
			background-color: palette('primary');
			color: palette('white');
			&:hover {
				color: palette('white');
			}
		}
	}
}

@media screen {
	.push-top {
		margin-top: 78.4px;
	}
}

@media screen and (max-width:breakpoint('tablet-landscape')) {
	
}