/* CSS Document */
#mobile-phone {
	display: none;
}
#mobile-toggle {
		display:none;
}
#mobile-menu {
		display:none;
}
#mobile-nav {
	.nav-link {
		font-weight: 500;
	}
	.sub-menu {
		padding-left: 2em;
		margin: 1em 0px;
		border-top: 1px solid #DA0017;
		border-bottom: 1px solid #DA0017;
		.nav-link {
			font-weight: 400;
		}
	}
}
#menu {
	display: block;
	@include border-radius(0px);
	ul {
		padding:0;
		li {
			list-style:none;
			position: relative;
			@include box-sizing(border-box);
			&.bfd-navButton {
				display: flex;
				justify-content: center;
				align-items: center;
				a {
					background-color: palette('primary');
					line-height: 1.5em;
    				height: 2.5em;
					border-radius: 5px;
					color: palette('white');
					
					&:hover {
						color: palette('white');
					}
					i:before {
						transform: rotateY(180deg);
					}
				}
			}
			a {
				height: 100%;
				color: #000000;
				margin-left:.5em;
				font-weight: 600;


				&:hover {
					cursor: pointer;
					color:palette('primary');
				}

				&.login {

				}

			}
//			&.menu-item-has-children {
//				.has-submenu + ul li {
//					opacity: 0.3;
//					transition: opacity 0.3s linear;
//					
//					&:nth-child(2) { transition-delay: 0.3s; }
//					&:nth-child(3) { transition-delay: 0.6s; }
//					&:nth-child(4) { transition-delay: 0.9s; }
//					&:nth-child(5) { transition-delay: 1.2s; }
//					&:nth-child(6) { transition-delay: 1.5s; }
//				}
//				.has-submenu.highlighted + ul li {
//					opacity: 1;
//				}
//
//			}
			.sub-menu {
				padding:0em;

				&:before {

				}

				position: absolute;
				display: none;
				min-width:250%;
				left: calc(100% - 125%);
				padding-top:0em;
				li {
					display: block;
					width:100%;
					padding:0em;
					background-color:palette('primary');

					&:last-child {
						@include border-radius(0px 0px 5px 5px);
					}

					a {
						color: palette('white');
						display: block;
						padding:.2em;
						line-height: 1.5em;
						height: auto;
					}
					&.has-children {
						.sub-menu {
							.nav-item {
								&:first-child {
									border-top: 2px solid #a80011;
								}
							}
						}
					}
				}
			}

		}
	}
}
.nav-link {
	margin:0;
}



.menu-item {
	position: relative;
}












@media (min-width: breakpoint('tablet-landscape') + 1px) {
	.sub-menu {
	  display: none;
	  position: absolute;
	  top: 100%;
	  left: 0;
	  width: 100px;
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -5px;
			height: 10px;
			width: 3px;
			background-color: #a80011;
			z-index: 10;
			box-shadow: 10px 4px 3px rgba(0, 0, 0, 0.1);
			animation: pullDown 0.6s forwards;
		}
		&:after {
			content: '\f043';
			font-family: 'FontAwesome';
			position: absolute;
			top: 0;
			right: -15px;
			color: #a80011 ;
			font-size: 30px;
			z-index: 10;
			text-shadow: 10px 4px 4px rgba(0, 0, 0, 0.1);
			animation: pullDownHandler 0.6s forwards;
		}

	  .menu-item {
		opacity: 0;
		text-align: center;
		padding: 0.5rem 0;
		cursor: pointer;
		border-bottom: 2px solid #a80011;
		&:hover {
		  background-color: #aaa;
		}
		&:last-child {
			border-bottom: none;
	  	}
	  }
	}

	// animate subnav__items if .nav__content.highlighted

	.nav-link.has-submenu.highlighted {
	  + .sub-menu .menu-item {
		animation: show 0.6s forwards;

		@for $i from 2 through 15 {
		  &:nth-child(#{$i}) {
			animation-delay: ($i / 10) + s;
		  }
		}
	  }
	}

	@keyframes pullDown {
	  100% {
		height: 85%;
	  }
	}
	
	@keyframes pullDownHandler {
		100% {
			top: calc(85% - 9px);
		}
	}

	@keyframes show {
	  0% {
		transform: translateY(-20px);
		opacity: 0;
	  }
	  100% {
		opacity: 1;
	  }
	}
}
















@media screen and (max-width:breakpoint('tablet-landscape')) {
	#mobile-phone {
		display: inline-block;
		text-align: right;
		padding:0.6em 0em;
		font-size: 1.8em;
	}
	#mobile-toggle {
		display:inline-block;
		text-align:right;
		padding:1.4em 0em;
		@include box-sizing(border-box);
		a {
			color:palette('primary');
			i {
				font-size:2em;
				@include box-sizing(border-box);
			}
		}
	}
	#menu {
		display:none;
	}
	#mobile-menu {
		position:fixed;
		display:block;
		width:320px;
		@include box-sizing(border-box);
		padding:0em 1.5em 1.5em;
		top:0;
		right:-400px;
		height: 100vh;
		overflow-y:auto;
		background-color:palette('white');
		z-index: 99999;
		border-left:4px solid #DA0017;
		box-shadow: 2px 2px 10px palette('black');

		#mobile-menu-top {
			padding-top:.5em;
			@include box-sizing(border-box);

			#mobile-menu-close {
				text-align: right;
				a {
					i {
						font-size:1.4em;
					}
				}
			}
		}

		ul {
			padding:0;
			li {
				list-style: none;
				a {

				}
			}
		}
		&.toggle-closed {
			right:-400px;
		}
		&.toggle-open {
			right:0px;
		}
	}
	.admin-bar #mobile-menu {
		top:32px;

		&.sticky {
			top:0px;
		}
	}
}
@media screen and (max-width:breakpoint('tablet-portrait')) {
	#mobile-toggle {
		display:inline-block;
		text-align:right;
		padding:1.4em 0em;
		@include box-sizing(border-box);
		a {
			color:palette('primary');
			i {
				font-size:2em;
				@include box-sizing(border-box);
			}
		}
	}
	#menu {
		display:none;
	}
	#mobile-menu {
		position:fixed;
		display:block;
		width:320px;
		@include box-sizing(border-box);
		padding:0em 1.5em 1.5em;
		top:0;
		right:-400px;
		height: 100vh;
		overflow-y:auto;
		background-color:palette('white');
		z-index: 99999;
		border-left:4px solid #DA0017;
		box-shadow: 2px 2px 10px palette('black');

		#mobile-menu-top {
			padding-top:.5em;
			@include box-sizing(border-box);

			#mobile-menu-close {
				text-align: right;
				a {
					i {
						font-size:1.4em;
					}
				}
			}
		}

		ul {
			padding:0;
			li {
				list-style: none;
				a {

				}
			}
		}
		&.toggle-closed {
			right:-400px;
		}
		&.toggle-open {
			right:0px;
		}
	}
	.admin-bar #mobile-menu {
		top:46px;

		&.sticky {
			top:0px;
		}
	}
}
@media screen and (max-width:breakpoint('mobile-lg')) {
	#mobile-toggle {
		display:inline-block;
		text-align:right;
		padding:1.4em 0em;
		@include box-sizing(border-box);
		a {
			color:palette('primary');
			i {
				font-size:2em;
				@include box-sizing(border-box);
			}
		}
	}
	#menu {
		display:none;
	}
	#mobile-menu {
		position:fixed;
		display:block;
		width:320px;
		@include box-sizing(border-box);
		padding:0em 1.5em 1.5em;
		top:0;
		right:-400px;
		height: 100vh;
		overflow-y:auto;
		background-color:palette('white');
		z-index: 99999;
		border-left:4px solid #DA0017;
		box-shadow: 2px 2px 10px palette('black');

		#mobile-menu-top {
			padding-top:.5em;
			@include box-sizing(border-box);

			#mobile-menu-close {
				text-align: right;
				a {
					i {
						font-size:1.4em;
					}
				}
			}
		}

		ul {
			padding:0;
			li {
				list-style: none;
				a {

				}
			}
		}
		&.toggle-closed {
			right:-400px;
		}
		&.toggle-open {
			right:0px;
		}
	}
	.admin-bar #mobile-menu {
		top:46px;

		&.sticky {
			top:0px;
		}
	}
}